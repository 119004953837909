import React, { Component } from 'react';
import styled from '@emotion/styled'
import videojs from 'video.js'
import PropTypes from 'prop-types'



const VideoWrapper = styled('div')`
    position:relative;
    box-sizing: border-box;    

`;

class SiteVideo extends Component {
    constructor(props){
        super()
        this.state.video = props.videoData
        this.state.id = props.videoData.id

      this.videoJsOptions = {
       
        controls: true,
        controlBar:{"pictureInPictureToggle":false},
        sources: [{
          src: this.state.video.src,
          type: this.state.video.type
        }],
        
        id: 'site_video_' + this.state.id,
        fluid:'true',
        maxWidth:'100%',
        poster: this.state.video.poster,
        name:this.state.video.title,
        onReady:this.onPlayerReady.bind(this),
        onPlay:this.onVideoPlay.bind(this),
        onPause:this.onVideoPause.bind(this),
        onTimeUpdate:this.onVideoTimeUpdate.bind(this),
        onSeeking:this.onVideoSeeking.bind(this),
        onSeeked:this.onVideoSeeked.bind(this),
        onEnd:this.onVideoEnd.bind(this)
      }
    }
    
    
    player = {}
    state = {}
    

    componentDidMount() {

        // instantiate Video.js
        this.player = videojs(this.videoNode, this.videoJsOptions, function playerReady() {
            
    
        });
        this.player.on("play", this.onVideoPlay)
        this.player.on("click", this.handleClick)
        this.player.addClass('vjs-rounded')        
      }
    
      // destroy player on unmount
      componentWillUnmount() {
        if (this.player) {
          this.player.dispose()
        }
      }
    
    handleClick(e){
        e.preventDefault()
        e.stopPropagation()
        console.log("VIDEO CLICKED: " + this.state.video)
    }
 
    onPlayerReady(player){
        console.log("Player is ready: ", player);
        this.player = player;
    }
 
    onVideoPlay(duration){
        console.log("Video played at: ", duration);

    }
 
    onVideoPause(duration){
        console.log("Video paused at: ", duration);
    }
 
    onVideoTimeUpdate(duration){
        console.log("Time updated: ", duration);
    }
 
    onVideoSeeking(duration){
        console.log("Video seeking: ", duration);
    }
 
    onVideoSeeked(from, to){
        console.log(`Video seeked from ${from} to ${to}`);
    }
 
    onVideoEnd(){
        console.log("Video ended");
    }
 
    render() {
        //console.log("RENDERING: " + this.state.video.src)

        // return (
        //     <VideoWrapper>
        //         <VideoPlayer  { ...videoJsOptions } />
        //         <VideoTitle>
        //             {this.state.video.title}
        //         </VideoTitle>
        //     </VideoWrapper>           
        // );

      
        return (
            <VideoWrapper>
                <div>	
                    <div data-vjs-player>
                        <video ref={ node => this.videoNode = node } className="video-js"></video>
                    </div>
                </div>
            </VideoWrapper>
        )
          
    }
}
export default SiteVideo;


SiteVideo.propTypes = {
    videoData: PropTypes.object
  }