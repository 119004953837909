import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import CloseIconSvg from '../../../assets/svg/close-icon.svg'

const CloseIconCtl = (props) =>{

const CloseIconDiv = styled('div')`  
cursor: pointer;
height:auto;
&:hover .x_close_icon_div_323{
    transform: scale(1.15);
}
`;

    return (
        <CloseIconDiv  tabIndex={props.tabIndexNum} style={props.wrapperProps}  onClick={props.clickCallback} ><CloseIconSvg className="x_close_icon_div_323" /></CloseIconDiv>
    )
}

export default CloseIconCtl

CloseIconCtl.propTypes = {
    clickCallback: PropTypes.func,
    tabIndexNum: PropTypes.number,
    wrapperProps: PropTypes.object
  }