import React, {Component} from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import SiteImage from '../images/site_images'
import LeftArrowCtl from '../components/ui/leftarrowcontrol'
import RightArrowCtl from '../components/ui/rightarrowcontrol'
import CloseIconCtl from '../components/ui/closeicon'



const OverlayBackground = styled('div')`
    position:fixed;
    z-index:500;
    height: 100vh;
    width: 100vw;
    top:0;
    background-color: rgba(0,0,0,0.8);
`;

const ViewportDiv = styled('div')`
    position: absolute;
    width: 90%;
    height: 90%;
    z-index: 530;
    margin: auto;
    background-color: #AAAAAA;
    opacity: 1;
    padding: 0;
    top:0; right: 0; left: 0; bottom:0;
`;

const leftArrowProps = {
    position: 'absolute',
    left: '1%',
    top: '50%',
    transform: 'translateY(-50%)',
    width: 'calc(3rem + 2vw)',
    opacity: 0.9
    
}

const rightArrowProps = {
    position: 'absolute',
    right: '1%',
    top: '50%',
    transform: 'translateY(-50%)',
    width: 'calc(3rem + 2vw)',
    opacity: 0.9
}


const closeIconProps = {
    position: 'absolute',
    width: 'calc(3rem + .5vw)',
    height: 'calc(3rem + .3vw)',
    top: '2%',
    right: '1%',
    opacity: .7
}

const imgStyle = {objectFit:"contain"};

class SiteOverlay extends Component {
    constructor(props) {
        super(props)
        this.state={}
         this.props = props
         this.overlayref = React.createRef()
         this.state.display = "none"
    }

    componentDidMount(){
        document.addEventListener('OverlayOpen', this.OpenOverlay)
    }

    componentWillUnmount(){
        document.removeEventListener('OverlayOpen', this.OpenOverlay)
    }

    OpenOverlay = (e) => {
        e.preventDefault()
        e.stopPropagation()
        this.setState({display:"block"})
    }


    HandleRightArrowClick = (e) => {
        e.preventDefault()
        e.stopPropagation()
        this.props.arrowClickCallback(1)
    }
    
    HandleLeftArrowClick = (e) => {
        e.preventDefault()
        e.stopPropagation()
        this.props.arrowClickCallback(-1)
    }

    HandlePicOverlayClick = (e) => {
        e.preventDefault()
        e.stopPropagation()
        //don't do anything
    }

    HandleOverlayClick = (e) => {
        e.preventDefault()
        e.stopPropagation()
        this.setState({display:"none"})
        this.props.closeCallback()
    }

    HandleCloseIconClick = (e) => {
        e.preventDefault()
        e.stopPropagation()
        this.setState({display:"none"})
        this.props.closeCallback()
    }

    HandleKeyDown = (e) => {
            alert('adsfasdfasdfasdfasdfasdf')
    }

               
    render = () => {
        return(
            
            <OverlayBackground ref={this.overlayref} style={{display:this.state.display}} onClick={this.HandleOverlayClick}>
                <ViewportDiv onClick={this.HandlePicOverlayClick}>                      
                    {this.props.children}
                    <CloseIconCtl id="overlayclose" tabIndexNum={0} wrapperProps={closeIconProps} clickCallback={this.HandleCloseIconClick} />  
                    <RightArrowCtl id="overlayrightarrow" tabIndexNum={0} wrapperProps={rightArrowProps} clickCallback={this.HandleRightArrowClick} />
                    <LeftArrowCtl  id="overlayleftarrow"  tabIndexNum={0} wrapperProps={leftArrowProps} clickCallback={this.HandleLeftArrowClick} />  
                </ViewportDiv>                 
            </OverlayBackground>
            
        )
        }
}

export default SiteOverlay

SiteOverlay.propTypes = {
    children: PropTypes.node.isRequired,
    arrowClickCallback: PropTypes.func,
    closeCallback: PropTypes.func
  }