import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import LeftArrowSvg from '../../../assets/svg/left-arrow.svg'

const LeftArrowCtl = (props) =>{

const LeftArrowDiv = styled('div')`  
cursor: pointer;
height:auto;
&:hover .x_left_arrow_div_443{
    transform: scale(1.15);
}
`;

    return (
        <LeftArrowDiv  tabIndex={props.tabIndexNum} style={props.wrapperProps}  onClick={props.clickCallback}  ><LeftArrowSvg className="x_left_arrow_div_443" /></LeftArrowDiv>
    )
}

export default LeftArrowCtl

LeftArrowCtl.propTypes = {
    clickCallback: PropTypes.func,
    tabIndexNum: PropTypes.number,
    wrapperProps: PropTypes.object
  }