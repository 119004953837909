import React from 'react'
import Panel from './panel'
import styled from '@emotion/styled'
import useSiteImages from '../../images/use-imagedata'
import Gallery from '../../images/galleryWithOverlay'
import PanelHeader from './panelHeader'

const GalleryPanel = () => {
    const {edges} = useSiteImages();

    const PanelContent = styled('article')`      
        padding-bottom: 20px;
        width:100%; 
        background-color:white;
        border-bottom: solid 10px gray; 
    `;
    
    return (
        <Panel panelId="gallery_panel"> 
            <PanelHeader title="Gallery" />
            <PanelContent>
                <Gallery imageData={edges}  />            
            </PanelContent>    
             
      </Panel>
    )
  }

  export default GalleryPanel;


