import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import {keyframes} from "@emotion/react"


const panelfadein = keyframes`
0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const PanelAsSection = styled('section')`
  width: 100%;
  display: block;
  opacity: 0;
  &.already-visible{
    opacity:1;
  }
  &.fade-in{
        animation: ${panelfadein} 1.5s;
  }
`;


const Panel = ( props ) => {
   
    return ( 
        <PanelAsSection id={props.panelId} className="SitePanel" >
            {props.children}
        </PanelAsSection>     
    )
  }
  
  Panel.propTypes = {
    children: PropTypes.node.isRequired,
    panelId: PropTypes.string.isRequired
  }
  
  export default Panel
  