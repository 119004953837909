import React, {Component} from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'




{/* <dt>
  <button aria-expanded="true"
          class="Accordion-trigger"
          aria-controls="sect1"
          id="accordion1id">
    <span class="Accordion-title">
      Early Life
      <span class="Accordion-icon"></span>
    </span>
  </button>
</dt>
<dd id="sect1"
     role="region"
     aria-labelledby="accordion2id"
     class="Accordion-panel">
        Katherine Audas grew up playing music in Boise, Idaho, with her identical twin sister, Jacqueline. 
</dd>
<h3> */}

const AccordionParent = styled('div')`
    margin: 0;
    padding: 0;
    border: 2px solid hsl(0, 0%, 82%);
    border-radius: 7px;
    width: 20em;
`;

const AccordionHeader = styled('dt')`
    
    display: block;
    margin: 0;
    background: linear-gradient(#aaaaaa, #dddddd);
    position: relative;
    width: 100%;
    height: calc(2rem + 1vw);
    border: solid 1px transparent;
    margin-bottom:2px;

    :hover, :focus{
        linear-gradient(#888888, #aaaaaa);
    }


`;

const AccordionIcon = styled('span')`
    border: solid hsl(0, 0%, 62%);
    border-width: 0 2px 2px 0;
    height: 0.5rem;
    pointer-events: none;
    position: absolute;
    right: .5rem;
    top: 50%;
    transform: translateY(-60%) rotate(45deg);
    width: 0.5rem;
`;

const AccordionTitle = styled('span')`
    display: block;
    font-size:  calc(1rem + .3vw);
    pointer-events: none;
    background: transparent;
  `;

const AccordionButton = styled('button')`
    display: block;
    position:absolute;
    width: 100%;
    height: 100%;
    background: transparent;
    top:0;
    bottom: 0;
`;

const AccordionPanel = styled('dd')`
    margin: 0;
    padding: 1em 1.5em;
    display:none;
    overflow-y:auto;
  `;


class AccordionUnit extends Component {
    constructor(props) {
        super(props)

        this.state = {}
        this.state.expanded = this.props.expanded
        this.state.panelID = "Panel_" + this.props.id
    }


    componentDidMount (){
        document.addEventListener('CollapseAccordions', this.HandleCollapseEvent)
    }

    componentWillUnmount(){
        document.removeEventListener('CollapseAccordions', this.OpenOverlay)
    }

    HandleClick = (e) => {
        e.preventDefault()
        e.stopPropagation()
        document.dispatchEvent(new Event('CollapseAccordions'))
        this.setState({expanded: !this.state.expanded})
    }

    HandleCollapseEvent =(e) => {
        e.preventDefault()
        e.stopPropagation()
        if( this.state.expanded){
            this.setState({expanded:false})
        }
    }

    render(){

        return(
            <>
            <AccordionHeader role="heading" aria-level="3" className="XCAccordionHeader" >
                <h3>
                    <AccordionButton 
                            aria-expanded={this.state.expanded}
                            onClick={this.HandleClick}
                            className="Accordion-trigger"
                            aria-controls="sect1"
                            id={this.props.id}>
                        <AccordionTitle>
                            {this.props.headerTitle}
                           
                        </AccordionTitle>
                       
                    </AccordionButton>
                </h3>
            </AccordionHeader>
            <AccordionPanel id={this.state.panelID}
                style={{display:this.state.expanded == true ? "block" : "none"}}
                role="region"
                aria-labelledby={this.props.id}>
               {this.props.children}
            </AccordionPanel>
            </>
        )
    }
}

export default AccordionUnit


AccordionUnit.propTypes = {
    id: PropTypes.string.isRequired,
    headerTitle: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    expanded: PropTypes.bool.isRequired
}