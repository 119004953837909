import React, {useEffect} from "react"
import isVisible from "./isVisible"


const InitPanelScroll = () => {
    // Already visible panels
useEffect(() => {
    var allPanels = document.getElementsByClassName("SitePanel");
    console.log( "PANELS: " + allPanels.length)
    for( var i = 0; i < allPanels.length; i++ ){
        var el = allPanels.item(i)
        //console.log( el.getAttribute('id'))
        if ( isVisible(el, true)){
       // console.log("A: " + el.classList)
        console.log( el.getAttribute('id'))
      //  console.log("A: " + el.classList)
        el.classList.add("already-visible")
      //  console.log("B: " + el.classList)
        }
    }

    window.onscroll = function(event) {
      var allPanels = document.getElementsByClassName("SitePanel");
      for( var i = 0; i < allPanels.length; i++ ){
        var el = allPanels.item(i)
        if ((isVisible(el,true))&& (!el.classList.contains("already-visible")) ){
          el.classList.add("fade-in", "already-visible"); 
        } 
      }
    }
    
    });
    

}

export default InitPanelScroll;