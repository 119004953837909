import React, {useState} from 'react';
import { useForm } from 'react-hook-form';
import styled from '@emotion/styled'

const AWS_API_POST_URL = "https://ej7ze13e3b.execute-api.us-west-2.amazonaws.com/prod";

export default () => {
  const { register, 
        handleSubmit, 
        reset,
        errors
        } = useForm();

  const [submitted, setSubmitted] = useState(false);

  const postToAWS = async data => {
    try {
        //console.log( JSON.stringify(data))
        await fetch(AWS_API_POST_URL, {
          method: "POST",
          mode: "cors",
          cache: "no-cache",
          body: JSON.stringify(data),
          headers: {
            "Content-type": "application/json; charset=UTF-8"
          }
        }).then(async (response) => {
            return response
        });    
      } catch (error) {
          console.log(error)
      }
  }

  const onSubmit = async data => {
      //console.log( (data))
      await postToAWS(data).then( result => {
        reset();
        setSubmitted(true)
      })
      
  }

  const showSubmitError = msg => <p style={{color:'white'}} className="msg-error">{msg}</p>;

  const showThankYou = (
    <div className="msg-confirm" style={{color:'white'}}>
        <p style={{fontSize: '1rem'}}>Your message was sent. Thank you!</p>
        <button type="button" onClick={() => setSubmitted(false)}>
        Send another message?
        </button>
    </div>
    );
    

  const ContForm = styled('form')`
    width:100%; 
    margin-bottom: 20px;
  `;
  const ContactTitle = styled('h5')`
    color: white;
    margin-bottom: 4px;
  `;

  const FormDiv = styled('div')`
    margin-left: 10%;
    width:75%;
  `;

  const label = styled('label')`
    margin-bottom: 25px;
  `;

  const InputDiv = styled('div')`
    margin-bottom: 1rem;
  `;

  
  const showForm = (
    <ContForm onSubmit={handleSubmit(onSubmit)}>
        <InputDiv>
        <label>
            <ContactTitle>Name*</ContactTitle>
            <input type="text" placeholder="Name" name="name" ref={register({required: true, maxLength: 80})} 
            style={{width:'100%'}} />
        </label>
        </InputDiv>
        <InputDiv>
        <label>
            <ContactTitle>Email*</ContactTitle>
            <input type="text" placeholder="Email" name="email" ref={register({required: true, pattern: /^\S+@\S+$/i})} 
            style={{width:'100%'}} />
        </label>
        </InputDiv>
        <InputDiv>
        <label>
            <ContactTitle>Subject*</ContactTitle>
            <input type="text" placeholder="Subject" name="subject" ref={register} 
            style={{width:'100%'}}/>
        </label>
        </InputDiv>
        <InputDiv>
        <label>
            <ContactTitle>Message*</ContactTitle>
            <textarea rows="4" name="message" ref={register} 
            style={{width:'100%'}}/>
        </label>
        </InputDiv>

        <button type="submit">
          Send
        </button>
    </ContForm>
  );

  return(
    <>
         {errors && errors.submit && showSubmitError(errors.submit.message)}
         <FormDiv> 
         {submitted ? showThankYou: showForm}
         </FormDiv>
         </>
  )
}