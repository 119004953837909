import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

const PanelHeaderContent = styled('header')`
padding: 20px 0px 20px 20px; 
cursor:default;
background-color:;       
`;

const TitleText = styled('h2')`
font-size: calc(.3rem + 2vw);
font-style: italic;  
margin-bottom: 0;
`;

const PanelHeader = (props) => {


    return(

        <PanelHeaderContent>
            <TitleText>
                {props.title}
            </TitleText>
        </PanelHeaderContent>

    )
}

export default PanelHeader

PanelHeader.propTypes = {
    title: PropTypes.string.isRequired
  }