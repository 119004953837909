import React from 'react'
import Panel from './panel'
import styled, {css} from '@emotion/styled'
import SiteImage from '../../images/site_images'
import useSiteMetadata from '../../hooks/use-sitemetadata';
import {breakpoint} from '../../styles/breakpoints'
import DownArrowCtl from '../ui/downarrowcontrol'


const HomePanel = () => {

    const { title, description, headerTitle, headerMenus } = useSiteMetadata();
  
    const PanelContent = styled('article')`
      position: relative;
      display: grid;
      grid-template-areas: 'a' 'b';
      
      width: 100vw;
      margin-bottom: 5vh;

      @media ${breakpoint.mobileL} {
        font-size: calc(1.3rem + 1vw);
        
        grid-template-areas: 'c b b a a'; 
        }
    `;

    const TitleDiv = styled('div')`
        display: flex;
        flex-direction: column;
        grid-area: a;
        background-color: black;
        color:white;
  
        width: 100%;
        text-align: center;
        padding: 5% 0;
        
      @media ${breakpoint.mobileL} {
        background-color:white;
        color:black;
        height:auto;
        padding: 20% 0;
      }
    `;


    const ContentDiv = styled('div')`
      border-radius: 0;
      box-shadow: none;
      overflow:hidden;
      
      grid-area: b;
     
      margin:auto;
      margin-top: 5vh;
      width: 100%;
      @media ${breakpoint.mobileL} {
        border-radius: 15px;
        box-shadow: 15px 15px 5px #aaaaaa;
      }
    `;

    const SiteTitle = styled('label')`
    font-size: calc(1.3rem + 5vw);
    font-style: italic;
    font-family: Palatino;
    cursor:default;
    padding: 5% 0;

    @media only screen and ${breakpoint.mobileL} {
    font-size: calc(.5rem + 2.5vw);
    
    }
    `;

    const HandleDownArrowClick = (e) => {
      e.preventDefault()
      e.stopPropagation()

        if( typeof document != 'undefined'){
            let bioPanel = document.querySelector('#bio_panel')
            if ( bioPanel !=null){
              bioPanel.scrollIntoView({behavior:"smooth"})
              //  let rect = bioPanel.getBoundingClientRect()
              //  let options = {
              //      left: bioPanel.scrollLeft - rect.width,
              //      top: rect.top,
              //      behavior: 'smooth'
               //}
               //bioPanel.scroll(options)
            }else{
              console.log("XXXXXXXXXXXXXX")
            }
        }
    };

    const downArrowProps = {
      position: 'relative',    
       width: 'calc(1rem + 1vw)',      
   }

   const DownArrowContainer = styled('div')`
      display: none;
      @media only screen and ${breakpoint.mobileL} {
        width: 10%;
        display:block;
        margin:auto;
        }
   `;

    

    const imgStyle = {objectFit:"cover" };
   
    return (
        <Panel panelId="home_panel"> 
          <PanelContent> 
            <TitleDiv>
              <SiteTitle>
                {headerTitle}
              </SiteTitle>
              <DownArrowContainer>
                <DownArrowCtl tabIndexNum={0}  wrapperProps={downArrowProps} clickCallback={HandleDownArrowClick} />
              </DownArrowContainer>
            </TitleDiv>
            <ContentDiv>        
              <SiteImage imageStyle={imgStyle} imageName="jake1" />
            </ContentDiv>  
          </PanelContent> 
        </Panel>
    )
  }

  export default HomePanel;


