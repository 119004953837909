import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import DownArrowSvg from '../../../assets/svg/down-arrow.svg'

const DownArrowCtl = (props) =>{

    const DownArrowDiv = styled('div')`  
    cursor: pointer;
    height:auto;
    &:hover .x_down_arrow_div_444{
        transform: scale(1.15);
    }`;

    return (
        <DownArrowDiv tabIndex={props.tabIndexNum} style={props.wrapperProps}  onClick={props.clickCallback} ><DownArrowSvg className="x_down_arrow_div_444" /></DownArrowDiv>
    )
}

export default DownArrowCtl

DownArrowCtl.propTypes = {
    clickCallback: PropTypes.func.isRequired,
    tabIndexNum: PropTypes.number,
    wrapperProps: PropTypes.object
  }