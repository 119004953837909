

      
const isVisible = function(el, partial) {
    
    var viewTop       = window.pageYOffset,
        viewBottom    = viewTop + window.innerHeight,
        _top          = el.offsetTop,
        _bottom       = _top + el.offsetHeight,
        compareTop    = partial === true ? _bottom : _top,
        compareBottom = partial === true ? _top : _bottom;
  
  return ((compareBottom <= viewBottom) && (compareTop >= viewTop));

};
  
export default isVisible;