import React from 'react'
import Panel from './panel'
import styled from '@emotion/styled'
import ContactForm from '../cForm'
import PanelHeader from './panelHeader'

const ContactPanel = () => {
     
    const PanelContent = styled('article')`

        width:100%; 
        padding-bottom:30px;
        border: solid 20px #EEEEEE;
    `;

    return (
        <Panel panelId="contact_panel">
            <PanelHeader title="Please contact me. I'd love to hear from you!" /> 
            <PanelContent>
                <ContactForm />
            </PanelContent>
      </Panel>
    )
  }

  export default ContactPanel;


