import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import PropTypes from "prop-types"

const SiteImage = ( {imageName, imageStyle, wrapperStyle} ) => {

  const data = useStaticQuery(graphql`
  fragment servicesImage on File {
    childImageSharp {
      sizes(maxWidth: 800, quality:90) {
        ...GatsbyImageSharpSizes_tracedSVG
      }
    }
  }

  query {

      brahms_double: file(relativePath: { eq: "img_files/brahms_double.jpeg" }) {
        ...servicesImage
      } 
        
      brahms_poster: file(relativePath: { eq: "img_files/brahms_poster.jpeg" }) {
        ...servicesImage
      }

      headshot1: file(relativePath: { eq: "img_files/headshot1.jpeg" }) {
        ...servicesImage
      }

      headshot2: file(relativePath: { eq: "img_files/headshot2.jpeg" }) {
        ...servicesImage
      }
      
      headshot3: file(relativePath: { eq: "img_files/headshot3.jpg" }) {
        ...servicesImage
      }

      headshot4: file(relativePath: { eq: "img_files/headshot4.jpg" }) {
        ...servicesImage
      }

      jake_arkady: file(relativePath: { eq: "img_files/jake_arkady.png" }) {
        ...servicesImage
      }

      jake_kathy1: file(relativePath: { eq: "img_files/jake_kathy1.png" }) {
        ...servicesImage
      }

      jake_kathy2: file(relativePath: { eq: "img_files/jake_kathy2.png" }) {
        ...servicesImage
      }   
      
      jake_kathy3: file(relativePath: { eq: "img_files/jake_kathy3.jpeg" }) {
        ...servicesImage
      }

      jake_kathy6: file(relativePath: { eq: "img_files/jake_kathy6.jpg" }) {
        ...servicesImage
      }

      jake_nz1: file(relativePath: { eq: "img_files/jake_nz1.png" }) {
        ...servicesImage
      }

      jake_nz2: file(relativePath: { eq: "img_files/jake_nz2.jpeg" }) {
        ...servicesImage
      }

      jake_nz3: file(relativePath: { eq: "img_files/jake_nz3.jpeg" }) {
        ...servicesImage
      }

      jake1: file(relativePath: { eq: "img_files/jake1.jpeg" }) {
        ...servicesImage
      }

      jake2: file(relativePath: { eq: "img_files/jake2.png" }) {
        ...servicesImage
      } 
      
      jake3: file(relativePath: { eq: "img_files/jake3.png" }) {
        ...servicesImage
      }

      jake4: file(relativePath: { eq: "img_files/jake4.png" }) {
        ...servicesImage
      }

      jake5: file(relativePath: { eq: "img_files/jake5.png" }) {
        ...servicesImage
      }

      jake6: file(relativePath: { eq: "img_files/jake6.png" }) {
        ...servicesImage
      }

      jake7: file(relativePath: { eq: "img_files/jake7.png" }) {
        ...servicesImage
      }

      jake8: file(relativePath: { eq: "img_files/jake8.png" }) {
        ...servicesImage
      }

      jake9: file(relativePath: { eq: "img_files/jake9.png" }) {
        ...servicesImage
      }

      orch1: file(relativePath: { eq: "img_files/orch1.jpeg" }) {
        ...servicesImage
      }

      orch2: file(relativePath: { eq: "img_files/orch2.png" }) {
        ...servicesImage
      }

      youngjake1: file(relativePath: { eq: "img_files/youngjake1.png" }) {
        ...servicesImage
      }

      youngjake2: file(relativePath: { eq: "img_files/youngjake2.png" }) {
        ...servicesImage
      }
      
    }
  `)
      
    return (
      <Img style={wrapperStyle} imgStyle={imageStyle} fluid={data[imageName].childImageSharp.sizes} />
    )}

    SiteImage.propTypes = {
        imageName: PropTypes.node.isRequired,
        imageStyle: PropTypes.object.isRequired,
        wrapperStyle: PropTypes.object
      }


    export default SiteImage