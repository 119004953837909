import React from 'react'
import Panel from './panel'
import styled from '@emotion/styled'
import SiteImage from '../../images/site_images'
import PanelHeader from './panelHeader'
import AccordionUnit from '../ui/accordion'
import {breakpoint} from '../../styles/breakpoints'
import CelloPrint from '../../../static/cello_print.png'
import useSiteMetadata from '../../hooks/use-sitemetadata';



const BioPanel2 = () => {

    const  {bioMenus2} =  useSiteMetadata(); 

    const PanelDiv = styled('div')`
      
      height: 6vw;
    `;

    const PanelContent = styled('article')`
    position: relative;
    width:100%; 
    background-color:white;  
    border-top: 1px solid gray;
    height: 60vw;   
    @media only screen and ${breakpoint.mobileL} {
        height: 40vw;
        margin-bottom: 5vh;
    }
`;

const BioContainer = styled('div')`
    display: grid;
    position: relative;
    grid-template-columns: 33% auto;
    grid-template-areas: 'a b';
    margin-top: 0px;
    width: 100%;
    height: 100%;
    overflow:hidden;

    @media only screen and ${breakpoint.mobileL} {
        grid-template-columns: 20% auto 30%;
        grid-template-areas: 'a b c';
    }
    `;

    const BioText = styled('div')`
        font-size: calc(.6rem + .6vw);
        overflow-y: auto;
        padding: 10px 10px 10px 20px;
        height: 100%;
        width: 100%;
        cursor:default;
        background-color: #EEEEEE;

        ul {
            margin-left: .3rem;

            li {
                margin-left: 0;
            }
        }
        
        @media only screen and ${breakpoint.mobileL} {
            padding: 30px 30px 90px 30px;
        }   
    `;
    const BioPic = styled('div')`
    display: none;
    @media only screen and ${breakpoint.mobileL} {
        display:block;
       grid-area: c;
       box-sizing: border-box;
        width: 100%;
        padding-left: calc(5px + 1vw);
        padding-top: 6%;
    }
    `;

    const BioCello = styled('div')`
    grid-area: a;
    width: 100%;
    height: 100%;
    background-image: url(${CelloPrint});
    background-repeat: no-repeat;
    background-size: contain;
`;

    const BioTextContainer = styled('div')`
        grid-area: b;
        position: relative;
        box-sizing: border-box;
        padding-left: calc(5px + 1vw);
        top: 5%;
        width: 100%;
        max-height: 90%;
        overflow-y:auto;
    `;

    const MenuContainer = styled('div')`
        display:flex;
        flex-direction: column;
        justify-content: flex-start;
        position: relative;
    
        top: 5%;
        height:80%;
        float: right;
        width: 90%;      
    `;

    const MenuItem = styled('button')`
    font-size:  calc(.7rem + .7vw);
    font-family: serif;
    font-weight: 600;
    padding-top: 2px;
    padding-bottom: 8px;
    background: linear-gradient(#aaaaaa, #dddddd);
    width: 100%;
    text-align: left;
    
    
    cursor: auto;
    border: 0;
    opacity: 0.90;
    margin-bottom: 2%;
    transition: transform .5s;
    &:hover {
        transform: scale(1.05);
        color: red;
    }
    &.bioSelected{
        color: white;
        background: linear-gradient(#555555, #999999);
        opacity: .95;
    }

    @media only screen and ${breakpoint.mobileL} {
        padding-top: calc( 2px + 1vw);
        padding-bottom: calc( 2px + 1vw);
    }`;

    const HandleMenuClick = (e, label) => {
       // console.log( 'clicked: ' + label)
        e.preventDefault()
        e.stopPropagation()
        let menu =  document.getElementById('bio_' + label)
        if( menu != null ){
            if ( !menu.classList.contains('bioSelected')){
               // console.log( 'found button')
                let oldMenus = document.getElementsByClassName('bioSelected')
                if( oldMenus.length > 0 ) {
                  //  console.log('found oldmenu' + oldMenus.length)
                    for( let i = 0; i < oldMenus.length; i++){
                        let menu = oldMenus.item(i)
                       // console.log( menu.classList)
                        menu.classList.remove('bioSelected')
                        let biotext = document.getElementById(menu.getAttribute('data-label').toLowerCase() + '_panel')
                        biotext.style.display = 'none'
                    }
                }else{
                   // console.log('no old menu found')
                }
                menu.classList.add('bioSelected')
                document.getElementById(label + '_panel').style.display = 'block'
            }else{
                console.log('already selected')
            }
        }
    }

    // background: ${props => `url(${CelloPrint}) no-repeat cover`}: 

    const imgStyle = {objectFit: "contain", height:"auto", objectPosition: '60% 40%'};
   
    return (
        <Panel panelId="bio_panel">
            <PanelDiv>
            </PanelDiv>     
            <PanelContent>            
                <BioContainer>  
                    <BioCello>
                        <MenuContainer>
                            {bioMenus2.map((item, i) => (
                                <MenuItem id={'bio_' + item.label.toLowerCase()}  data-label={item.label} className={i==0?"bioSelected biomenuitem":"biomenuitem"}  key={i} style={{order:item.order}} onClick={(e)=>HandleMenuClick(e, item.label.toLowerCase())} >
                                {item.label}
                                </MenuItem>
                            ))}                         
                        </MenuContainer>
                    </BioCello>
                    <BioTextContainer>
                            <BioText 
                                id="about_panel"
                                className="bioTextPanel bioTextSelected" 
                                style={{display:"block"}}>
                                    <p>Jacqueline Audas is a passionate violinist and compelling performer, focused on engaging and empowering communities 
                                    through classical music. Since a young age, she has believed in the power of music to create bonds between people. 
                                    This belief inspired her to found the non-profit organization, Classical C.A.R.M.A. (Concerts Aiming to Raise Money 
                                    and Awareness). As its artistic director, Jacqueline helps to produce and perform innovative benefit concert 
                                    series which: fundraise for organizations that aid those in need, bring awareness to obstacles faced by underserved 
                                    populations, and promote classical music education. This upcoming year, Classical C.A.R.M.A. will produce a series 
                                    of outreach and fundraising concerts in collaboration with S.E.A.R.C.H., a Houston, Texas non-profit that works 
                                    to end the cycle of homelessness. The goal is to entertain and fundraise, while highlighting the importance and 
                                    value of  S.E.A.R.C.H.’s work.</p>
                                    <p>In the past few years, Jacqueline has made appearances abroad in Israel, Germany, Italy, Spain, and New Zealand 
                                        and performed as a soloist, chamber, and orchestral musician around the United States. Most recently, 
                                        she has performed at the North Shore Chamber Music Festival, the Aspen Music Festival, the Keshet Eilon 
                                        Mastercourse and the Sommermusik im Oberen Nagoldtal. Jacqueline has been awarded the top prize in multiple 
                                        competitions, and was a finalist in the Barlassina International Young Talents Competition and a 
                                        quarter-finalist in both the 2019 Michael Hill International Violin Competition and the 2020 Elmar Oliveira 
                                        International Violin Competition.</p>
                                    <p>Jacqueline Audas recently graduated with her Master’s Degree in Violin Performance from Rice University’s Shepherd School
                                         of Music, where she held the Anne and Charles Duncan Concertmaster Chair. While completing both her Bachelor’s 
                                         and Master’s Degrees at the Shepherd School of Music, she studied with Paul Kantor. Prior to attending university, 
                                         she studied with the late Arkady Fomin. Currently, she works with Vadim Gluzman at Johns Hopkins’ Peabody Institute.</p>
                            </BioText>
                        </BioTextContainer>                          
                    <BioPic>
                        <SiteImage imageStyle={imgStyle} imageName="jake9"/>
                    </BioPic>            
                </BioContainer>               
            </PanelContent>            
      </Panel>
    )
  }

  export default BioPanel2;


