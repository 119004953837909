const scrollToAnchor = (id) => {
    if( typeof document !== 'undefined'){
     if ( typeof window !== 'undefined'){
         window.scrollTo({
           top: document.getElementById(id).offsetTop,
           behavior: "smooth"
         });
       }
    }
 }

 export default scrollToAnchor