import React from 'react'
import PropTypes from 'prop-types'
import styled, {css} from '@emotion/styled'
import useSiteMetadata from '../../hooks/use-sitemetadata';
import {breakpoint} from '../../styles/breakpoints'
import SiteVideo from '../../videos/site_video'
import useVideoData from '../../videos/use-videodata'


const videos = useVideoData();


const VideoBlob = (props) => {

    const { title, description, headerTitle, headerMenus } = useSiteMetadata();

    const BlobContent = styled('div')`
      position: relative;

      @media ${breakpoint.mobileL} {     
        
        
        }
    `;

    const VideoContainer = styled('div')`
      border-radius: 0;
      box-shadow: none;
      overflow:hidden;
     
      margin:auto;
      width: 100%;
      @media ${breakpoint.mobileL} {
        border-radius: 15px;
        box-shadow: 15px 15px 5px #aaaaaa;
      }
    `;



    const imgStyle = {objectFit:"cover" };
   
    return (
         
          <BlobContent> 
            <VideoContainer>        
              <SiteVideo title="test" videoData={videos[props.videoId]} /> 
            </VideoContainer>  
          </BlobContent> 
       
    )
  }
  VideoBlob.propTypes = {
    videoId: PropTypes.string.isRequired
  }
  export default VideoBlob;


