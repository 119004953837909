
import { useStaticQuery, graphql } from "gatsby"

const useSiteImages = ( filter ) => {


    const data = useStaticQuery(graphql`
    {
      source: allFile(
        sort: {
          fields: [childImageSharp___fluid___originalName]
          order: ASC
        }, 
        filter: {absolutePath: {regex: "images/img_files/"}}) {
          edges {
            node { childImageSharp {
                 sizes(maxWidth: 800,) {
                   originalName
                ...GatsbyImageSharpSizes_tracedSVG
                }
              }
            }
          }
        }
      }
    `)
      
      
    return (
      data.source 
    )}

    

    export default useSiteImages