

const useVideoData = () => {
    const videos = {}
        videos.GhostTrio = {
                id: "ghosttrio_short",
                title: "Beethoven: Ghost Trio",
                desc: "Catherine Drinker Bower said it best: \“chamber music [is] a conversation between friends.\” Here I am with my friend, Rūta Kuzmickas, on piano and my twin sister, Katherine Audas, on cello. Playing chamber music is always an opportunity to connect with other musicians and experience the value of colleagues' opinions - one of the best ways to enjoy the stage!", 
                src:"https://d3tx3k6ax12aot.cloudfront.net/JK/GhostTrio/SD/E0/GhostTrio.mp4", 
                poster: "https://d3tx3k6ax12aot.cloudfront.net/JK/GhostTrio/Ghost_Poster_720.png",
                type: "video/mp4",
                layout: "landscape"
                }
        videos.Subito = {
                id: "subito_short",
                title: "Lutoslawski: Subito",
                desc: "Lutoslawski wrote Subito shortly after being diagnosed with terminal cancer.  I believe we can hear his anger, his strife, his disbelief, and his fear of the unknown in the unpredictable and abrupt stops, and changes in dynamic, rhythm, and mood. He once said about his compositions, \“I write music that is an expression of my own tastes, wishes, and desires ...[it is] my own internal truth.\” ", 
                src:"https://d3tx3k6ax12aot.cloudfront.net/JK/Subito/SD/E0/Subito.mp4", 
                poster: "https://d3tx3k6ax12aot.cloudfront.net/JK/Subito/Subito_Poster_640.png",
                type: "video/mp4",
                layout: "portrait"
                }
        videos.Mozart3 = {
                id: "mozart_short",
                title: "Some Mozart",
                desc: "I know that my exploration of Mozart’s music will be a life long journey. This excerpt is from a recent recording of Mozart’s violin concerto No. 3 in G major, a piece in which the joy is tangible from the opening orchestra chord. ", 
                src:"https://d3tx3k6ax12aot.cloudfront.net/JK/Mozart/SD/E0/Mozart.mp4", 
                poster: "https://d3tx3k6ax12aot.cloudfront.net/JK/Mozart/Mozart_Poster_640.png",
                type: "video/mp4",
                layout: "portrait"
                }
        videos.Adagio = {
                id: "adagio_short",
                title: "A Little Bach",
                desc: "Lorem ipsum dolor sit amet, ex cum omnis propriae torquatos. Ullum eirmod labores ut has, assum delicata nam et. Vim cu sonet sanctus aliquando. Dicta deseruisse dissentias eu eam, duo elit definitionem et, mel et brute senserit sadipscing.", 
                src:"https://d3tx3k6ax12aot.cloudfront.net/JK/Adagio/SD/E0/Adagio.mp4", 
                poster: "",
                type: "video/mp4",
                layout: "portrait"
                }
        videos.Barber = {
                id: "barber",
                title: "A Snippet of Barber",
                desc: "Samuel Barber’s violin concerto is possibly my favorite concerto. I love the exquisite textures interweaving the solo and orchestral lines. Immediately engaging, passionate, and thrilling, it ends in an increasingly intense and dramatic whirlwind of notes. This clip is from a performance in March 2018 with colleagues at the Shepherd School of Music at Rice University.", 
                src:"https://d3tx3k6ax12aot.cloudfront.net/JK/Barber/SD/E0/Barber.mp4", 
                poster: "https://d3tx3k6ax12aot.cloudfront.net/JK/Barber/Barber_Poster_720.png",
                type: "video/mp4",
                layout: "landscape"
                }
        videos.Bruch = {
                id: "bruch3",
                title: "Bruch: Violin Concerto No. 1 in G minor, Op. 26 : III. Finale: Allegro energico", 
                desc: "Lorem ipsum dolor sit amet, ex cum omnis propriae torquatos. Ullum eirmod labores ut has, assum delicata nam et. Vim cu sonet sanctus aliquando. Dicta deseruisse dissentias eu eam, duo elit definitionem et, mel et brute senserit sadipscing.",
                src: "https://d3tx3k6ax12aot.cloudfront.net/JK/Bruch3rd/SD/E0/Bruch3rd.mp4", 
                poster: "",
                type: "video/mp4",
                layout: "landscape"
                }
        videos.Trio = {
                id: "Beethoven",
                title: "Beethoven: Piano Trio in D Major, op. 70 No. 1 “Ghost”, Allegro vivace e con brio",
                desc: "Lorem ipsum dolor sit amet, ex cum omnis propriae torquatos. Ullum eirmod labores ut has, assum delicata nam et. Vim cu sonet sanctus aliquando. Dicta deseruisse dissentias eu eam, duo elit definitionem et, mel et brute senserit sadipscing.", 
                src: "https://d3tx3k6ax12aot.cloudfront.net/Beethoven/SD/E0/Beethoven.mp4", 
                poster:"https://d3tx3k6ax12aot.cloudfront.net/Beethoven/Beethoven_600.png",
                type: "video/mp4",
                layout: "landscape"
                }
        videos.Grieg = {
                id: "griegMP4",
                desc: "Grieg's Sonata in C Minor is one of my absolute favorite works to perform and collaborate on. The heroism is gripping and dramatic, while the beautiful lyricism, particularly in the second movement, is nostalgic and dream-like. These clips are from a recital in March of 2019.",
                title: "A Bit of Grieg", 
                src: "https://d3tx3k6ax12aot.cloudfront.net/JK/Grieg/SD/E0/Grieg.mp4", 
                poster:"https://d3tx3k6ax12aot.cloudfront.net/JK/Grieg/Grieg_Poster_640.png",
                type:"video/mp4",
                layout: "portrait"
                },
        videos.Kodaly = {
                id: "kodalyduo",
                desc: "This is Zoltán Kodály’s Duo for violin and cello, performed with my sister, Katherine Audas, in 2020. One of our favorite works, it is fiery, emotionally gripping, and full of Hungarian flair. It’s hard to believe the range of dynamics and colors he creates, ingeniously writing for just two instruments.",
                title: "Kodály Duo for Violin and Cello", 
                src: "https://d3tx3k6ax12aot.cloudfront.net/JK/KodalyDuo/SD/E0/KodalyDuo.mp4", 
                poster:"",
                type:"video/mp4",
                layout: "landscape"
                }, 
        videos.BachSonata = {
                id: "bachsonata",
                desc: "J.S. Bach’s Sonata No. 2 is one of internal reflection, technical challenge, and brilliant harmonization. This recording is of the grave and fuga movements. See if you can notice how many sections Bach seamlessly crafts in the fugue!",
                title: "Bach, Sonata in A minor", 
                src: "https://d3tx3k6ax12aot.cloudfront.net/JK/BachSonata/SD/E0/BachSonata.mp4", 
                poster:"",
                type:"video/mp4",
                layout: "portrait"
                },
        videos.Messiaen = {
                id: "messiaen",
                desc: "Olivier Messiaen’s Fantaisie for violin and piano was only recently published in 2007, although it was written in 1933 within a year of his Theme and Variations. This work is quite breathtaking in its soaring and ecstatic melody in combination with  highly rhythmic sections. It is short, but captivating. This recording was from a recital with the fabulous Kyung-A Yoo.",
                title: "Messiaen Fantaisie", 
                src: "https://d3tx3k6ax12aot.cloudfront.net/JK/MessiaenFantasie/SD/E0/MessiaenFantasie.mp4", 
                poster:"",
                type:"video/mp4",
                layout: "portrait"
                }

    
    return videos
      
}

export default useVideoData;