import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import RightArrowSvg from '../../../assets/svg/right-arrow.svg'

const RightArrowCtl = (props) =>{

const RightArrowDiv = styled('div')`  
cursor: pointer;
height:auto;
&:hover .x_right_arrow_div_444{
    transform: scale(1.15);
}
`;

    return (
        <RightArrowDiv tabIndex={props.tabIndexNum} style={props.wrapperProps}  onClick={props.clickCallback} ><RightArrowSvg className="x_right_arrow_div_444" /></RightArrowDiv>
    )
}

export default RightArrowCtl

RightArrowCtl.propTypes = {
    clickCallback: PropTypes.func,
    tabIndexNum: PropTypes.number,
    wrapperProps: PropTypes.object
  }