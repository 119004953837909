export const deviceWidth = {
    mobileS: '320px',
    mobileM: '375px',
    mobileL: '425px',
    tablet: '768px',
    laptop: '1024px',
    desktop: '1440px'
}

export const breakpoint = {
    mobileS: `(max-width: ${deviceWidth.mobileS})`,
    mobileM: `(max-width: ${deviceWidth.mobileM})`,
    mobileL: `(min-width: ${deviceWidth.mobileL})`,
    tablet: `(max-width: ${deviceWidth.tablet}) and (-webkit-device-pixel-ratio: 2)`,
    laptop: `(max-width: ${deviceWidth.laptop})`,
    laptopL: `(max-width: ${deviceWidth.laptopL})`,
    desktop: `(max-width: ${deviceWidth.desktop})`
  };