const outOfViewport = (elem) => {
   
	// Get element's bounding
    var bounding = elem.getBoundingClientRect();
    // slack value for close enough
    let sortof = 5;

	// Check if it's out of the viewport on each side
	var out = {};
	out.top = bounding.top < 0;
	out.left = bounding.left + sortof < 0;
	out.bottom = bounding.bottom > (window.innerHeight || document.documentElement.clientHeight);
	out.right = (bounding.right - sortof)> ((window.innerWidth  ) || (document.documentElement.clientWidth ));
	out.any = out.top || out.left || out.bottom || out.right;
	out.all = out.top && out.left && out.bottom && out.right;

	return out;

}

export default outOfViewport