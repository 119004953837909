



const GalleryImgData = (imageQueryResult) => {

    const galleryList = {
        'headshot3':{
            file: 'headshot3.jpg',
            title: 'Jacqueline and Poggi',
            desc: 'Jake and violin',
            overlay_key: 'headshot3'
        },   
        'jake_nz2':{
            file: 'jake_nz2.jpeg',
            title: 'Jacqueline in New Zealand',
            desc: 'Jacqueline',
            overlay_key: 'jake_nz2'
        },        
       /* 'jake_kathy6':{
            file: 'jake_kathy6.jpg',
            title: 'Jacqueline and Katherine Audas',
            desc: 'Portrait of Jacqueline and Katherine',
            overlay_key: 'jake_kathy6'
        }, 
        */
        'headshot4':{
            file: 'headshot4.jpg',
            title: 'Jacqueline and Poggi',
            desc: 'Jake and violin',
            overlay_key: 'headshot4'
        },   
        'orch1':{
            file: 'orch1.jpeg',
            title: 'Jacqueline performing',
            desc: 'Jacqueline performing the Barber Concerto',
            overlay_key: 'orch1'
        },    
     /*   'headshot1':{
            file: 'headshot1.jpeg',
            title: 'Jacqueline',
            desc: 'Jacqueline Audas',
            overlay_key: 'headshot1'
        }, 
        */
     /*   'headshot2':{
            file: 'headshot2.jpeg',
            title: 'Katherine and orchestra',
            desc: 'Katherine at Ima Hogg Competition',
            overlay_key: 'headshot2'
        },
        */
        'jake_arkady':{
            file: 'jake_arkady.png',
            title: 'Jacqueline and Arkday',
            desc: 'Jacqueline with Arkady Fomin',
            overlay_key: 'jake_arkady'
        },
        'jake_kathy1':{
            file: 'jake_kathy1.png',
            title: 'Jacqueline and Katherine Audas',
            desc: 'Portrait of Jacqueline and Katherine',
            overlay_key: 'jake_kathy1'
        }, 
        'jake8':{
            file: 'jake8.png',
            title: 'Jacqueline',
            desc: 'Jacqueline in the Mountains',
            overlay_key: 'jake8'
        },  
        'jake_kathy3':{
            file: 'jake_kathy3.jpeg',
            title: 'Jacqueline and Katherine Audas',
            desc: 'Jacqueline and Katherine in Carnegie Hall',
            overlay_key: 'jake_kathy3'
        }, 
        'jake_nz1':{
            file: 'jake_nz1.png',
            title: 'Jacqueline in New Zealand',
            desc: 'Jacqueline, Lake',
            overlay_key: 'jake_nz1'
        },
        'brahms_poster':{
            file: 'brahms_poster.jpeg',
            title: 'Katherine and Cello',
            desc: 'Texas Medical Center Orchestra Performance',
            overlay_key: 'brahms_poster'
        },
        'jake_nz3':{
            file: 'jake_nz3_v.jpeg',
            title: 'Jacqueline in New Zealand',
            desc: 'Jacqueline in Queenstown',
            overlay_key: 'jake_nz3'
        },      
     /*   'jake1':{
            file: 'jake1.jpeg',
            title: 'Jacqueline performing',
            desc: 'Jacqueline performing the Sibelius Concerto',
            overlay_key: 'jake1'
        },
        */   
        'jake2':{
            file: 'jake2.png',
            title: 'Jacqueline in hallway',
            desc: 'Portrait of Jacqueline',
            overlay_key: 'jake2'
        },   
        'brahms_double':{
            file: 'brahms_double.jpeg',
            title: 'Jacqueline and Katherine Audas',
            desc: 'Performing the Brahms Double Concerto',
            overlay_key: 'brahms_double'
        },
        'jake3':{
            file: 'jake3.png',
            title: 'Jacqueline headshot',
            desc: 'Jaqueline',
            overlay_key: 'jake3'
        },   
        'jake4':{
            file: 'jake4.png',
            title: 'Jacqueline',
            desc: 'Jacqueline recital',
            overlay_key: 'jake4'
        }
        /*,   
        'jake5':{
            file: 'jake5.png',
            title: 'Jacqueline performing',
            desc: 'Jacqueline recital',
            overlay_key: 'jake5'
        }
        'youngjake1':{
            file: 'youngjake1.png',
            title: 'Jacqueline age 4',
            desc: 'Jacqueline age 4',
            overlay_key: 'youngjake1'
        },
        'youngjake2':{
            file: 'youngjake2.png',
            title: 'Jacqueline age 3',
            desc: 'Jacqueline age 3',
            overlay_key: 'youngjake2'
        }
        */
    }
    

    // clone the gallery list to set length of completed gallery
    let images = Object.keys(galleryList)

    imageQueryResult.forEach(image => {
        let name = image.node.childImageSharp.sizes.originalName

        name = name.substr(0, name.lastIndexOf('.'))
        
        var index = images.indexOf(name)
        //console.log( name + ':' + index)
        if( index > -1 ){
            var desc = galleryList[name].desc
            image.imgStyle = { objectFit: 'cover', objectPosition: '40% 60%', alt:{desc}}
            image.imgStyle.title = name
            image.key = galleryList[name].overlay_key
            image.name = image.node.childImageSharp.sizes.originalName
            images[index] = image;  
        }               
    });
   
    return images
   
}

export default GalleryImgData