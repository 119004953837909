import React from 'react'
import Panel from './panel'
import PropTypes from 'prop-types'
import styled, {css} from '@emotion/styled'
import SiteImage from '../../images/site_images'
import useSiteMetadata from '../../hooks/use-sitemetadata';
import {breakpoint} from '../../styles/breakpoints'
import useVideoData from '../../videos/use-videodata'
import VideoBlob from '../ui/videoBlob'


const videos = useVideoData();


const VideoPanel = (props) => {

    const { title, description, headerTitle, headerMenus } = useSiteMetadata();

    const contentPos = props.videoPos == "left" ? "'a  b'":"'b  a'"
    const descRightPad = props.videoPos == "left" ? "0" : "2vw"
    const descLeftPad = props.videoPos == "left" ? "2vw" : "0"
    const padRightVideo = props.videoPos == "left" ? "5vw" : "0"
    const padLeftVideo = props.videoPos == "left" ? "0" : "5vw"
    const videoWidth = videos[props.videoId].layout == "portrait" ? "20vw" : "40vw"
    const videoID = props.videoId + "_panel"
  
    const PanelContent = styled('div')`
      position: relative;
      display: grid;
      grid-template-areas: 'a' 'b';     
      width: 100%;
      overflow:hidden;      

      @media ${breakpoint.mobileL} {
        grid-template-columns: 50% 50%;
        font-size: calc(1.3rem + 1vw);
        background-color: ${props.backColor};
        grid-template-areas: ${contentPos};         
        max-height: 50vw;
        }
    `;

    const ContentDiv = styled('div')`
      
      grid-area: a;
      width: 100%;
      

      @media ${breakpoint.mobileL} {
        padding-left: 5vw;
        padding-top: 3vw;
        padding-bottom: 3vw;
        max-height: inherit;
      }
    `;

    const TitleDiv = styled('div')`
    background-color: black;
    color:white; 
    text-align: center;
    padding: 5%  0;
    
  @media ${breakpoint.mobileL} {
    background-color: ${props.backColor};
    color:black;
    height:auto;
    
  }
`;

    const VideoTitle = styled('label')`
    font-size: calc(.5rem + 5vw);
    font-style: italic;
    font-family: Palatino;
    cursor:default;

    @media only screen and ${breakpoint.mobileL} {
    font-size: calc(1rem + 1vw);
    
    }
    `;

    const VideoDesc = styled('article')`
    font-size: calc(.3rem + 1vw);
    font-style: italic;
    font-family: Palatino;
    cursor:default;
    display: none;
    
    @media only screen and ${breakpoint.mobileL} {
      display: block;
      font-size: calc(0.7rem + .5vw);
      width: 80%;
      margin:auto;
      max-height: 70%;
      padding-left: ${descLeftPad};
      padding-right: ${descRightPad};
      
      overflow: scroll;
      cursor:default;
    }
    `;

    const VideoDiv = styled('div')`
      grid-area: b;
      display: flex;
        justify-content: center;
        background-color: black;
        overflow:hidden;

      @media only screen and ${breakpoint.mobileL} {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 3vw 0;
        background-color: ${props.backColor};
        max-height:inherit;
        
        }
    `;

    const VideoFrame = styled('div')`
    
      width:90%;
      
      @media only screen and ${breakpoint.mobileL} {
        width: ${videoWidth};
        
        }
    `;


    const imgStyle = {objectFit:"cover" };
   
    return (
        <Panel panelId={videoID}> 
          <PanelContent>
            <ContentDiv>
              <TitleDiv>
                <VideoTitle>
                  {videos[props.videoId].title}
                </VideoTitle> 
              </TitleDiv>
              <VideoDesc>
                {videos[props.videoId].desc}
              </VideoDesc>            
            </ContentDiv>
            <VideoDiv>
              <VideoFrame>
                <VideoBlob videoId={props.videoId} /> 
              </VideoFrame>
            </VideoDiv>
          </PanelContent>
        </Panel>
    )
  }
  VideoPanel.propTypes = {
    videoId: PropTypes.string.isRequired,
    videoPos: PropTypes.string,
    backColor: PropTypes.string
  }
  export default VideoPanel;


